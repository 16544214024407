import { Box, HStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { memo, useCallback, useMemo } from "react";
import { getCell } from ".";
import { Month } from "./Month";

/**
 * @typedef {object} RoomsVisualizationCalendarOnChangeParams
 * @property {import("dayjs").Dayjs | ""} checkin
 * @property {import("dayjs").Dayjs | ""} checkout
 * @property {boolean} shouldClose
 */

/**
 * @callback RoomsVisualizationCalendarOnChange
 * @param {RoomsVisualizationCalendarOnChangeParams} params
 * @returns {void}
 */

export const RoomsVisualizationCalendar = memo(
  /**
   * @typedef {object} Props
   * @property {import("dayjs").Dayjs} startDateCalendar
   * @property {number} monthsCount
   * @property {import("./types").VisualizationDataSet} visualizationData
   * @property {"checkin" | "checkout"} mode
   * @property {import("./types").CheckoutsData} checkoutsData
   * @property {import("dayjs").Dayjs | null} startDateSelection
   * @property {import("dayjs").Dayjs | null} endDateSelection
   * @property {string[]} selectedDates
   * @property {import("react").Dispatch<import("react").SetStateAction<string[]>>} setSelectedDates
   * @property {import("react").Dispatch<import("react").SetStateAction<import("dayjs").Dayjs | null>>} setEndDateSelection
   * @property {import("react").Dispatch<import("react").SetStateAction<import("dayjs").Dayjs | null>>} setStartDateSelection
   * @property {() => void} reset
   * @property {import("react").Dispatch<import("react").SetStateAction<import("./types").CheckoutsData>>} setCheckoutsData
   * @property {import("react").Dispatch<import("react").SetStateAction<"checkin" | "checkout">>} setMode
   * @property {RoomsVisualizationCalendarOnChange} onChange
   * @property {boolean} withPrices
   * @property {boolean} isValidating
   * @property {() => void} [onClickPrev]
   * @property {() => void} [onClickNext]
   * @property {boolean} [withAllAvailableSelectableDates]
   * @property {boolean} preventPast prevent user from going into the past by disabling prev month button
   */
  /**
   * @param {Props} props
   */
  function RoomsVisualizationCalendar({
    startDateCalendar,
    monthsCount,
    visualizationData,
    mode,
    checkoutsData,
    startDateSelection,
    endDateSelection,
    selectedDates,
    setSelectedDates,
    setEndDateSelection,
    reset,
    setStartDateSelection,
    setCheckoutsData,
    setMode,
    onChange,
    withPrices,
    isValidating,
    onClickPrev,
    onClickNext,
    preventPast,
    withAllAvailableSelectableDates = false,
  }) {
    const startDatesMonths = useMemo(() => {
      return Array.from({ length: monthsCount }, (_, index) => {
        return startDateCalendar.add(index, "month");
      });
    }, [monthsCount, startDateCalendar]);

    // #region onCellClick
    const onCellClick = useCallback(
      /**
       * @param {object} params
       * @param {string} params.yearMonthDay
       */
      ({ yearMonthDay }) => {
        const splits = yearMonthDay.split("-");
        const yearMonth = `${splits[0]}-${splits[1]}`;
        const day = splits[2];
        const cell = getCell({
          data: visualizationData,
          yearMonth,
          day,
        });
        const dateCellClicked = dayjs(yearMonthDay);
        if (mode === "checkin") {
          if (!withAllAvailableSelectableDates) {
            if (
              !cell?.currentCell?.is_checkin_day ||
              cell.currentCell.state !== "av"
            ) {
              return;
            }
          }

          if (withAllAvailableSelectableDates) {
            if (cell?.currentCell.state !== "av") {
              return;
            }
          }

          setStartDateSelection(dateCellClicked);
          setEndDateSelection(dateCellClicked);
          setMode("checkout");
          /** @type {import("../../../containers/Rooms/Visualization/types").CheckoutsData} */
          const newCheckoutsData = {};
          if (
            cell?.currentCell.checkout &&
            !Array.isArray(cell?.currentCell.checkout)
          ) {
            const entries = Object.entries(cell?.currentCell.checkout);
            for (let i = 0; i < entries.length; i++) {
              const [yearMonthDay, checkoutData] = entries[i];
              const splits = yearMonthDay.split("-");
              const yearMonth = `${splits[0]}-${splits[1]}`;
              const day = splits[2];
              if (!newCheckoutsData[yearMonth]) {
                newCheckoutsData[yearMonth] = {};
              }
              newCheckoutsData[yearMonth][day] = checkoutData;
            }
            setCheckoutsData(newCheckoutsData);
            setSelectedDates([yearMonthDay]);
          }
        }
        if (
          mode === "checkout" &&
          startDateSelection !== null &&
          endDateSelection !== null
        ) {
          if (!withAllAvailableSelectableDates) {
            if (
              checkoutsData[endDateSelection.format("YYYY-MM")]?.[
                endDateSelection.format("DD")
              ] === undefined
            ) {
              onChange({ checkin: "", checkout: "", shouldClose: false });
              reset();
              return;
            }
          }

          const cellCheckin = getCell({
            data: visualizationData,
            yearMonth: startDateSelection.format("YYYY-MM"),
            day: startDateSelection.format("DD"),
          });
          const keys = Object.keys(cellCheckin?.currentCell?.checkout ?? {});
          if (!withAllAvailableSelectableDates) {
            if (!keys.includes(endDateSelection.format("YYYY-MM-DD"))) {
              onChange({ checkin: "", checkout: "", shouldClose: false });
              reset();
              return;
            }
          }

          onChange({
            checkin: startDateSelection,
            checkout: endDateSelection,
            shouldClose: true,
          });
          reset();
        }
      },
      [
        checkoutsData,
        endDateSelection,
        mode,
        onChange,
        reset,
        setCheckoutsData,
        setEndDateSelection,
        setMode,
        setSelectedDates,
        setStartDateSelection,
        startDateSelection,
        visualizationData,
        withAllAvailableSelectableDates,
      ],
    );

    // #region onCellHover
    const onCellHover = useCallback(
      /**
       * @param {object} params
       * @param {import("dayjs").Dayjs} params.date
       */
      ({ date: dateHovered }) => {
        if (mode === "checkout") {
          if (startDateSelection) {
            const dates = [];
            let currentDate = startDateSelection;
            const cellStart = getCell({
              data: visualizationData,
              yearMonth: startDateSelection.format("YYYY-MM"),
              day: startDateSelection.format("DD"),
            });
            const checkoutDatesYMD = Object.keys(
              cellStart?.currentCell.checkout ?? {},
            );
            if (
              checkoutDatesYMD.includes(dateHovered.format("YYYY-MM-DD")) ||
              withAllAvailableSelectableDates
            ) {
              while (
                currentDate.isBefore(dateHovered) ||
                currentDate.isSame(dateHovered)
              ) {
                dates.push(currentDate.format("YYYY-MM-DD"));
                currentDate = currentDate.add(1, "day");
              }
              setSelectedDates(dates);
              setEndDateSelection(dayjs(dates[dates.length - 1]));
            } else {
              setSelectedDates([startDateSelection.format("YYYY-MM-DD")]);
              setEndDateSelection(startDateSelection);
            }
          }
        }
      },
      [
        mode,
        setEndDateSelection,
        setSelectedDates,
        startDateSelection,
        visualizationData,
        withAllAvailableSelectableDates,
      ],
    );

    // #region handleMouseOver
    const handleMouseOver = useCallback(
      /** @type {import("react").MouseEventHandler<HTMLDivElement>} */
      (event) => {
        if (event.target["classList"].contains("cell")) {
          const cell = /** @type {HTMLElement} */ (event.target);
          const date = cell.dataset.date;
          onCellHover({ date: dayjs(date) });
        }
      },
      [onCellHover],
    );

    // #region handleClick
    const handleClick = useCallback(
      /** @type {import("react").MouseEventHandler<HTMLDivElement>} */
      (event) => {
        const cell = event.target["closest"](".cell");
        if (cell) {
          onCellClick({ yearMonthDay: cell.dataset.date });
        }
      },
      [onCellClick],
    );

    return (
      <Box onClick={handleClick} onMouseOver={handleMouseOver}>
        <HStack spacing="16px">
          {startDatesMonths.map((startDateMonth, index) => {
            return (
              <Month
                key={index}
                startDateMonth={startDateMonth}
                visualizationDataMonth={
                  visualizationData[startDateMonth.format("YYYY-MM")]
                }
                mode={mode}
                checkoutsMonthData={
                  checkoutsData[startDateMonth.format("YYYY-MM")] ?? null
                }
                startDateSelection={startDateSelection}
                endDateSelection={endDateSelection}
                selectedDates={selectedDates}
                withPrices={withPrices}
                isValidating={isValidating}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                monthIndex={index}
                monthsCount={monthsCount}
                preventPast={preventPast}
                withAllAvailableSelectableDates={
                  withAllAvailableSelectableDates
                }
              />
            );
          })}
        </HStack>
      </Box>
    );
  },
);

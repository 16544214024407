import { defineMessage } from "react-intl";

export const MONTH_TO_YEAR_BASE = 12; // 0-11

export const DAY_TO_WEEK_BASE = 7; // 0–6

export const HOURS_TO_DAY_BASE = 24; // 0–23

export const MINUTES_TO_HOURS_BASE = 60; // 0–59

export const SECONDS_TO_MINUTES_BASE = 60; // 0–59

export const MILLISECONDS_TO_SECOND_BASE = 1000; // 0–999

// At minimum, there is 4 weeks in one month.
// Assuming the week begins a sunday:
//   sunday, 1st to saturday 28th (for a leap year).
export const MIN_WEEKS_PER_MONTH = 4;

// At maximum, there is 6 weeks in one month.
// Assuming the week begins a sunday:
//   saturday, 1st to monday 31th.
export const MAX_WEEKS_PER_MONTH = 6;

export const MIN_NUMBER_OF_DAYS_IN_MONTH = 28;
export const MAX_NUMBER_OF_DAYS_IN_MONTH = 31;

export const MAX_NUMBER_OF_DAYS_IN_MONTH_LIST = Array.from(
  Array(MAX_NUMBER_OF_DAYS_IN_MONTH).keys(),
).map((day) => day + 1);

export const WEEK_DAY_SUNDAY = 0;
export const WEEK_DAY_MONDAY = 1;
export const WEEK_DAY_TUESDAY = 2;
export const WEEK_DAY_WEDNESDAY = 3;
export const WEEK_DAY_THURSDAY = 4;
export const WEEK_DAY_FRIDAY = 5;
export const WEEK_DAY_SATURDAY = 6;

export const timeDayNamesMessages = defineMessage({
  id: "library.constants.time.dayNames",
  defaultMessage:
    "{day, select, 0 {di.} 1 {lu.} 2 {ma.} 3 {me.} 4 {je.} 5 {ve.} 6 {sa.} other{{day}} }",
});

export const MONTH_VALUE_JANUARY = {
  name: "january",
  id: 0,
  monthId: 1,
  numberOfDays: 31,
};

export const MONTH_VALUE_FEBRUARY = {
  name: "february",
  id: 1,
  monthId: 2,
  numberOfDays: 28,
};

export const MONTH_VALUE_MARCH = {
  name: "march",
  id: 2,
  monthId: 3,
  numberOfDays: 31,
};

export const MONTH_VALUE_APRIL = {
  name: "april",
  id: 3,
  monthId: 4,
  numberOfDays: 30,
};

export const MONTH_VALUE_MAY = {
  name: "may",
  id: 4,
  monthId: 5,
  numberOfDays: 31,
};

export const MONTH_VALUE_JUNE = {
  name: "june",
  id: 5,
  monthId: 6,
  numberOfDays: 30,
};

export const MONTH_VALUE_JULY = {
  name: "july",
  id: 6,
  monthId: 7,
  numberOfDays: 31,
};

export const MONTH_VALUE_AUGUST = {
  name: "august",
  id: 7,
  monthId: 8,
  numberOfDays: 31,
};

export const MONTH_VALUE_SEPTEMBER = {
  name: "september",
  id: 8,
  monthId: 9,
  numberOfDays: 30,
};

export const MONTH_VALUE_OCTOBER = {
  name: "october",
  id: 9,
  monthId: 10,
  numberOfDays: 31,
};

export const MONTH_VALUE_NOVEMBER = {
  name: "november",
  id: 10,
  monthId: 11,
  numberOfDays: 30,
};

export const MONTH_VALUE_DECEMBER = {
  name: "december",
  id: 11,
  monthId: 12,
  numberOfDays: 31,
};

export const MONTHS_VALUE_LIST = [
  MONTH_VALUE_JANUARY,
  MONTH_VALUE_FEBRUARY,
  MONTH_VALUE_MARCH,
  MONTH_VALUE_APRIL,
  MONTH_VALUE_MAY,
  MONTH_VALUE_JUNE,
  MONTH_VALUE_JULY,
  MONTH_VALUE_AUGUST,
  MONTH_VALUE_SEPTEMBER,
  MONTH_VALUE_OCTOBER,
  MONTH_VALUE_NOVEMBER,
  MONTH_VALUE_DECEMBER,
];

export const monthValuesMessage = defineMessage({
  defaultMessage: `{month, select,
    january {Janvier}
    february {Février}
    march {Mars}
    april {Avril}
    may {Mai}
    june {Juin}
    july {Juillet}
    august {Août}
    september {Septembre}
    october {Octobre}
    november {Novembre}
    december {Décembre}
    other {{month}}
  }`,
});

export const TIME_DELAY_VALUE_HOUR = "H";
export const TIME_DELAY_VALUE_DAY = "J";

export const TIME_DELAYS = {
  [TIME_DELAY_VALUE_HOUR]: {
    id: TIME_DELAY_VALUE_HOUR,
  },
  [TIME_DELAY_VALUE_DAY]: {
    id: TIME_DELAY_VALUE_DAY,
  },
};

export const TIME_DELAY_LIST = Object.values(TIME_DELAYS);

export const timeDelayMessage = defineMessage({
  defaultMessage: `{delay, select,
    H {Heures}
    J {Jour}
    other {{state}}
  }`,
});

import {
  Box,
  Button,
  Flex,
  Stack,
  useBreakpointValue,
  Wrap,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BOOKINGS_WORKFLOW_VALUE_DIRECT } from "../../../constants/bookings";
import { getCell } from "../../../containers/Rooms/Visualization";
import { RoomsVisualizationCalendar } from "../../../containers/Rooms/Visualization/Calendar";
import { ROOMS_VISUALIZATION_LEGEND_ITEMS } from "../../../containers/Rooms/Visualization/Legend";
import { useApi } from "../../../hooks/useApi";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import { RGrid } from "../../RGrid";

const MONTHS_COUNT_DESKTOP = 2;
const MONTHS_COUNT_MOBILE = 1;

// #region component
export const Body = memo(
  /**
   * @typedef {object} Props
   * @property {number} roomId
   * @property {number | null} bookingId
   * @property {number} capacity
   * @property {() => void} onClose
   * @property {"direct" | "system"} [workflow]
   * @property {(params: { checkin: string, checkout: string }) => void} onChange
   * @property {string} checkin
   * @property {string} checkout
   * @property {boolean} includeRestricted si `true`, considère les séjours avec des contraintes comme étant disponibles
   * @property {boolean} includeOnPast si `true`, renvoi aussi les séjours dans le passé
   * @property {boolean} includeWithoutPrices si `true`, considère les séjours sans tarifs comme étant disponibles
   */
  /**
   * @param {Props} props
   */
  function Body({
    roomId,
    bookingId,
    capacity,
    onClose,
    workflow,
    onChange,
    checkin,
    checkout,
    includeRestricted,
    includeOnPast,
    includeWithoutPrices,
  }) {
    const intl = useIntl();

    // onChange is a memoized callback to update the checkin and checkout dates
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    const mountedRef = useRef(false);

    const currentDate = useMemo(() => {
      return dayjs();
    }, []);

    const [mode, setMode] = useState(
      /** @type {"checkin" | "checkout"} */ ("checkin"),
    );

    const [startDateCalendar, setStartDateCalendar] = useState(() => {
      if (checkin !== "" && checkout !== "") {
        return dayjs(checkin).startOf("month").startOf("day");
      } else {
        return currentDate.startOf("month").startOf("day");
      }
    });

    const [visualizationData, setVisualizationData] = useState(
      /** @type {import("../../../containers/Rooms/Visualization/types").VisualizationDataSet} */ ({}),
    );

    const [checkoutsData, setCheckoutsData] = useState(
      /** @type {import("../../../containers/Rooms/Visualization/types").CheckoutsData} */ ({}),
    );

    const [startDateSelection, setStartDateSelection] = useState(
      /** @type {import("dayjs").Dayjs | null} */ (
        checkin !== "" && checkout !== "" ? dayjs(checkin) : null
      ),
    );
    const [endDateSelection, setEndDateSelection] = useState(
      /** @type {import("dayjs").Dayjs | null} */ (
        checkin !== "" && checkout !== "" ? dayjs(checkout) : null
      ),
    );
    const [selectedDates, setSelectedDates] = useState(
      /** @returns {string[]} */
      () => {
        if (checkin !== "" && checkout !== "") {
          const dates = [];
          let date = dayjs(checkin);
          const endDate = dayjs(checkout);
          while (date.isBefore(endDate) || date.isSame(endDate, "day")) {
            dates.push(date.format("YYYY-MM-DD"));
            date = date.add(1, "day");
          }
          return dates;
        }
        return [];
      },
    );

    const isMobile = useBreakpointValue({
      base: true,
      md: false,
    });

    const monthsCount = useMemo(() => {
      return isMobile ? MONTHS_COUNT_MOBILE : MONTHS_COUNT_DESKTOP;
    }, [isMobile]);

    // #region url
    const url = useMemo(() => {
      let fromDate = startDateCalendar;
      if (
        includeOnPast === false &&
        fromDate.isBefore(dayjs().startOf("day"), "day")
      ) {
        fromDate = dayjs().startOf("day");
      }
      return generateApiUrl({
        id: "@hotelsRoomsAvailabilitiesAction.byDates",
        parameters: {
          roomId,
        },
        query: {
          capacity,
          workflow,
          from: fromDate.format("YYYY-MM-DD"),
          to: fromDate.add(monthsCount, "month").format("YYYY-MM-DD"),
          include_restricted: includeRestricted,
          include_on_past: includeOnPast,
          include_without_prices: includeWithoutPrices,
          booking_id: bookingId,
        },
      });
    }, [
      capacity,
      includeOnPast,
      includeRestricted,
      includeWithoutPrices,
      monthsCount,
      roomId,
      startDateCalendar,
      workflow,
      bookingId,
    ]);

    // #region useApi visualization
    /** @type {import("../../../hooks/useApi").UseApi<import("../../../containers/Rooms/Visualization/types").VisualizationResponse>} */
    const { swrResponse: swrResponseVisualization } = useApi(url, {
      swrConfig: {
        onSuccess: (response) => {
          setVisualizationData((currentVisualizationData) => {
            /** @type {import("../../../containers/Rooms/Visualization/types").VisualizationDataSet} */
            const newVisualizationData = { ...currentVisualizationData };
            Object.keys(response.data).forEach((yearMonthDay) => {
              let dateOfPrevDay = dayjs(yearMonthDay).subtract(1, "day");
              /** @type {import("../../../containers/Rooms/Visualization/types").VisualizationCell} */
              const dayData = {
                currentCell: response.data[yearMonthDay],
                prevCell:
                  getCell({
                    data: newVisualizationData,
                    yearMonth: dateOfPrevDay.format("YYYY-MM"),
                    day: dateOfPrevDay.format("DD"),
                  })?.currentCell ?? undefined,
              };
              const [year, month, day] = yearMonthDay.split("-");
              const yearMonth = `${year}-${month}`;

              newVisualizationData[yearMonth] = {
                ...(newVisualizationData[yearMonth] ?? {}),
                [day]: dayData,
              };
            });
            return newVisualizationData;
          });
        },
      },
    });

    // #region handleChange
    const handleChange = useCallback(
      /** @type {import("../../../containers/Rooms/Visualization/Calendar").RoomsVisualizationCalendarOnChange} */
      ({ checkin, checkout, shouldClose }) => {
        onChangeRef.current({
          checkin: checkin !== "" ? checkin.format("YYYY-MM-DD") : "",
          checkout: checkout !== "" ? checkout.format("YYYY-MM-DD") : "",
        });
        shouldClose && onClose();
      },
      [onClose],
    );

    // #region reset
    const reset = useCallback(() => {
      setMode("checkin");
      setCheckoutsData({});
      setStartDateSelection(null);
      setEndDateSelection(null);
      setSelectedDates([]);
    }, []);

    // Reset the component when the critical props change
    // #region reset props change
    useEffect(() => {
      if (mountedRef.current === false) {
        return;
      }
      reset();
      setVisualizationData({});
      handleChange({
        checkin: "",
        checkout: "",
        shouldClose: false,
      });
    }, [
      includeRestricted,
      includeOnPast,
      includeWithoutPrices,
      reset,
      handleChange,
    ]);

    useEffect(() => {
      mountedRef.current = true;
    }, []);

    // #region handleClickPrev
    const handleClickPrev = useCallback(() => {
      setStartDateCalendar((currentStartDateCalendarYMD) => {
        return dayjs(currentStartDateCalendarYMD)
          .subtract(1, "month")
          .startOf("day");
      });
    }, []);

    // #region handleClickNext
    const handleClickNext = useCallback(() => {
      setStartDateCalendar((currentStartDateCalendarYMD) => {
        return dayjs(currentStartDateCalendarYMD)
          .add(1, "month")
          .startOf("day");
      });
    }, []);

    const handleClickReset = useCallback(() => {
      onChange({
        checkin: "",
        checkout: "",
      });
      reset();
    }, [onChange, reset]);

    // #region return
    return (
      <Stack spacing="16px">
        <Stack spacing="4px">
          <Flex justify="flex-end">
            <Button onClick={handleClickReset} size="sm" variant="outline">
              <FormattedMessage defaultMessage="Réinitialiser" />
            </Button>
          </Flex>
          <RGrid minCellWidth="500px">
            <RoomsVisualizationCalendar
              checkoutsData={checkoutsData}
              mode={mode}
              monthsCount={monthsCount}
              selectedDates={selectedDates}
              startDateCalendar={startDateCalendar}
              startDateSelection={startDateSelection}
              endDateSelection={endDateSelection}
              visualizationData={visualizationData}
              setSelectedDates={setSelectedDates}
              setEndDateSelection={setEndDateSelection}
              setStartDateSelection={setStartDateSelection}
              reset={reset}
              setCheckoutsData={setCheckoutsData}
              setMode={setMode}
              onChange={handleChange}
              withPrices={false}
              withAllAvailableSelectableDates={
                workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT
              }
              isValidating={swrResponseVisualization.isValidating}
              onClickPrev={handleClickPrev}
              onClickNext={handleClickNext}
              preventPast={includeOnPast === false}
            />
          </RGrid>
        </Stack>

        <Wrap
          direction={{ base: "column", md: "row" }}
          spacing={{ base: "0px", md: "16px" }}
          maxW="100%">
          {ROOMS_VISUALIZATION_LEGEND_ITEMS.map((item, index) => (
            <Box key={index} display="flex" alignItems="center" mr="16px">
              <Box
                w="14px"
                h="14px"
                borderRadius="full"
                bg={item.color}
                mr="8px"
                borderWidth="1px"
                borderColor={item.difficultContrast ? "#ccc" : item.color}
              />
              {intl.formatMessage(item.label)}
            </Box>
          ))}
        </Wrap>
      </Stack>
    );
  },
);

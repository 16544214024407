import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { memo } from "react";
import { FormattedNumber } from "react-intl";
import { CELL_WIDTH } from "./Month";

/**
 * @param {object} param0
 * @param {boolean} param0.isCheckin
 * @param {import("./types").CheckoutDetail | null} param0.checkoutDetail
 * @param {"checkin" | "checkout"} param0.mode
 * @param {boolean} param0.withAllAvailableSelectableDates
 * @returns {boolean}
 */
function isDayAvailable({
  isCheckin,
  checkoutDetail,
  mode,
  withAllAvailableSelectableDates,
}) {
  if (mode === "checkin") {
    return isCheckin;
  }

  if (mode === "checkout") {
    return checkoutDetail !== null || withAllAvailableSelectableDates;
  }

  return false;
}

export const Cell = memo(
  /**
   * @typedef {object} Props
   * @property {import("./types").VisualizationCell} [cell]
   * @property {string} dayOfMonth
   * @property {string} yearMonthDay
   * @property {import("./types").CheckoutDetail | null} checkoutDetail
   * @property {boolean} isCheckin
   * @property {boolean} isStartSelection
   * @property {boolean} isEndSelection
   * @property {boolean} isSelected
   * @property {boolean} withPrices
   * @property {boolean} withAllAvailableSelectableDates
   * @property {"checkin" | "checkout"} mode
   */
  /**
   * @param {Props} props
   */
  function Cell({
    cell,
    dayOfMonth,
    yearMonthDay,
    checkoutDetail,
    isCheckin,
    isStartSelection,
    isEndSelection,
    isSelected,
    withPrices,
    withAllAvailableSelectableDates,
    mode,
  }) {
    const borderColor = useColorModeValue("gray.200", "gray.600");

    const isAvailable = isDayAvailable({
      isCheckin,
      checkoutDetail,
      withAllAvailableSelectableDates,
      mode,
    });

    return (
      <Box
        position="relative"
        className="cell"
        w={CELL_WIDTH}
        h={CELL_WIDTH}
        borderLeftWidth="1px"
        borderBottomWidth="1px"
        borderColor={borderColor}
        cursor={isAvailable ? "pointer" : "default"}
        backgroundColor={
          isAvailable
            ? "brandPrimary.500"
            : cell?.currentCell?.state === "av" ||
              cell?.prevCell?.state === "av"
            ? "brandPrimary.300"
            : "rgba(0,0,0,.25)"
        }
        data-date={yearMonthDay}>
        {isSelected && (
          <>
            {isStartSelection ? (
              <>
                {/* highlight triangle bottom */}
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  w="0"
                  h="0"
                  borderBottomWidth="49px"
                  borderBottomColor="brandSecondary.400"
                  borderLeftWidth="49px"
                  borderLeftColor="transparent"
                  pointerEvents="none"
                />

                <Box
                  position="absolute"
                  background="linear-gradient(to top left,
                  rgba(0,0,0,0) 0%,
                  rgba(0,0,0,0) calc(50% - 1px),
                  rgba(255,255,255,1) 50%,
                  rgba(0,0,0,0) calc(50% + 1px),
                  rgba(0,0,0,0) 100%)"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  pointerEvents="none"
                />
              </>
            ) : isEndSelection ? (
              <>
                {/* highlight triangle top */}
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  w="0"
                  h="0"
                  borderTopWidth="49px"
                  borderTopColor="brandSecondary.400"
                  borderRightWidth="49px"
                  borderRightColor="transparent"
                  pointerEvents="none"
                />
                <Box
                  position="absolute"
                  background="linear-gradient(to top left,
                  rgba(0,0,0,0) 0%,
                  rgba(0,0,0,0) calc(50% - 1px),
                  rgba(255,255,255,1) 50%,
                  rgba(0,0,0,0) calc(50% + 1px),
                  rgba(0,0,0,0) 100%)"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  pointerEvents="none"
                />
              </>
            ) : (
              // highlight rectangle
              <Box
                position="absolute"
                backgroundColor="brandSecondary.400"
                top="0"
                left="0"
                right="0"
                bottom="0"
                pointerEvents="none"
              />
            )}
          </>
        )}

        <Box
          position="absolute"
          top={withPrices ? "2px" : "50%"}
          right={withPrices ? "2px" : "50%"}
          transform={withPrices ? "none" : "translate(50%, -50%)"}
          pointerEvents="none">
          <Text
            textAlign="right"
            fontSize="1rem"
            lineHeight="1rem"
            userSelect="none"
            color="#fff"
            textShadow="1px 1px gray, -1px -1px gray, 1px -1px gray, -1px 1px gray, -1px 0px gray, 1px 0px gray, 0px 1px gray, 0px -1px gray">
            {dayOfMonth}
          </Text>
        </Box>

        {checkoutDetail && withPrices && (
          <Box
            position="absolute"
            bottom="0"
            left="0"
            w="full"
            pointerEvents="none">
            <Text
              fontSize="0.75rem"
              textAlign="center"
              color="#fff"
              userSelect="none">
              <FormattedNumber
                value={checkoutDetail?.periods_amount}
                maximumFractionDigits={0}
                currency="EUR"
                style="currency"
              />
            </Text>
          </Box>
        )}
      </Box>
    );
  },
);

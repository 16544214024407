import dayjs from "dayjs";

/**
 * Validate checkin max time. Substract 1 minute to allow 00:00 to be valid (condisered the last minute of the day)
 * @param {object} params
 * @param {string} params.minTime
 * @param {any} params.maxTime
 * @returns {boolean}
 */
export default function validateCheckinCheckoutMaxTime({ minTime, maxTime }) {
  const maximumTime = dayjs()
    .set("hour", maxTime.split(":")[0])
    .set("minute", maxTime.split(":")[1])
    .subtract(1, "minute")
    .format("HH:mm");

  const minimumTime = dayjs()
    .set("hour", Number(minTime.split(":")[0]))
    .set("minute", Number(minTime.split(":")[1]))
    .subtract(1, "minute")
    .format("HH:mm");

  return maximumTime >= minimumTime;
}

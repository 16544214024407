import {
  Button,
  Flex,
  Select,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFormContext, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";

import { CustomerTravelersAutocomplete } from "../../../../components/Form/CustomerTravelersAutocomplete";
import FormControlRHF from "../../../../components/ReactHookForm/FormControlRHF";
import ObserverRHF from "../../../../components/ReactHookForm/ObserverRHF";
import {
  customerTravelersGenderMessage,
  customerTravelersTypeMessage,
  CUSTOMER_TRAVELERS_GENDER_LIST,
  CUSTOMER_TRAVELERS_TYPES_LIST,
  CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR,
  CUSTOMER_TRAVELERS_TYPE_VALUE_SOCIETY,
} from "../../../../constants/customerTravelers";
import { useApi } from "../../../../hooks/useApi";
import useTranslate from "../../../../hooks/useTranslate";
import generateApiUrl from "../../../../libraries/utils/generateApiUrl";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import { useCallback, useEffect } from "react";
import { Icon } from "../../../../components/Icon";
import { BookingsCustomerGuestList } from "./GuestList";
import { Frame } from "../../../../components/Frame";
import { InputAddress } from "../../../../components/Form/InputAddress";
import { BOOKINGS_WORKFLOW_VALUE_DIRECT } from "../../../../constants/bookings";
import { EmailWithBlacklistVerify } from "../../../../components/Form/EmailWithBlacklistVerify";
import InputPhoneWithBlacklistVerify from "../../../../components/Form/InputPhoneWithBlacklistVerify";
import FormObserver from "../../../../components/ReactHookForm/FormObserver";

const FIELDS_TO_BE_SET_BY_CUSTOMER_TRAVELER_VIEW = [
  "type",
  "gender",
  "firstname",
  "lastname",
  "birthdate",
  "address",
  "address2",
  "zipcode",
  "city",
  "company",
  "siret",
  "vat_number",
  "email",
  "infos",
];

/**
 * @typedef {object} Props
 * @property {import("react").FC<{onSuccess: (clientId?: number) => void}> | null} ActionCreateCustomerComponent
 * @property {keyof typeof import("../../../../constants/carts").CARTS_WORKFLOWS} workflow
 */

/**
 * @param {Props} param0
 */
export function BookingFormCustomer({
  ActionCreateCustomerComponent,
  workflow,
}) {
  /** @type {import("react-hook-form").UseFormReturn<import("../../../../types/Cart").CartCreateDataFormValues>} */
  const { control, setValue, getValues } = useFormContext();

  /** @type {import("../../../../hooks/useApi").UseApi<import("../../../../types/Country").Country[]>} */
  const { swrResponse: countriesResponse } = useApi(
    generateApiUrl({
      id: "@countries.search",
      query: { per_page: 250 },
    }),
  );

  const countries = countriesResponse?.data?.data ?? [];

  const watchCustomerId = useWatch({ name: "data.main_guest.customer_id" });

  /** @type {import("../../../../hooks/useApi").UseApi<import("../../../../types/CustomerTraveler").CustomerTraveler>} */
  const { swrResponse: selectedCustomerResponse } = useApi(
    watchCustomerId
      ? generateApiUrl({
          id: "@customersTravelers.view",
          parameters: {
            travelerId: watchCustomerId,
          },
        })
      : null,
  );

  const selectedCustomer = selectedCustomerResponse?.data?.data ?? null;

  useEffect(() => {
    if (selectedCustomer) {
      const values = FIELDS_TO_BE_SET_BY_CUSTOMER_TRAVELER_VIEW.reduce(
        (acc, key) => ({
          ...acc,
          [key]: selectedCustomer?.[key] ?? "",
        }),
        {},
      );

      // Set the values in the form
      setValue(
        `data.main_guest`,
        {
          ...getValues(`data.main_guest`),
          ...values,
          country_id: selectedCustomer?.country?.id ?? "",
          phone1: selectedCustomer?.phone1?.number ?? "",
          phone2: selectedCustomer?.phone2?.number ?? "",
          phone3: selectedCustomer?.phone3?.number ?? "",
          phone1_country: selectedCustomer?.phone1?.country ?? "",
          phone2_country: selectedCustomer?.phone2?.country ?? "",
          phone3_country: selectedCustomer?.phone3?.country ?? "",
        },
        { shouldDirty: true },
      );
    }
  }, [getValues, selectedCustomer, setValue]);

  function onNewClient(clientId) {
    setValue("data.main_guest.customer_id", clientId, { shouldDirty: true });
  }

  const handleAutoFill = useCallback(
    /** @type {import("../../../../components/Form/InputAddress").InputAddressOnAutoFillCallback} */
    ({ address, city, zipcode }) => {
      address &&
        setValue("data.main_guest.address", address, { shouldDirty: true });
      city && setValue("data.main_guest.city", city, { shouldDirty: true });
      zipcode &&
        setValue("data.main_guest.zipcode", String(zipcode), {
          shouldDirty: true,
        });
    },
    [setValue],
  );

  const translate = useTranslate();

  const intl = useIntl();

  return (
    <VStack spacing="1rem" align="stretch">
      <Frame>
        <Text variant="h4" color="gray.500">
          {intl.formatMessage({
            defaultMessage: "Associer un client depuis ma base client",
          })}
        </Text>

        <Flex gap="1rem" width="full" alignItems="flex-end">
          <FormControlRHF
            label={intl.formatMessage({
              defaultMessage: "Client principal",
            })}
            width="full"
            name="data.main_guest.customer_id"
            renderWithFormControl={(fields) => (
              <CustomerTravelersAutocomplete {...fields} />
            )}
          />

          {ActionCreateCustomerComponent && (
            <ActionCreateCustomerComponent
              onSuccess={(clientId) => onNewClient(clientId)}>
              {function ({ onOpen, isAllowed }) {
                return (
                  isAllowed && (
                    <Button
                      onClick={onOpen}
                      aria-label={intl.formatMessage({
                        defaultMessage: "Créer un client",
                      })}
                      variant="outline"
                      leftIcon={<Icon icon="ms_add" />}>
                      {intl.formatMessage({
                        defaultMessage: "Créer un client",
                      })}
                    </Button>
                  )
                );
              }}
            </ActionCreateCustomerComponent>
          )}
        </Flex>
      </Frame>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="1rem">
        <FormControlRHF
          control={control}
          label={intl.formatMessage({
            defaultMessage: "Type de voyageur",
          })}
          rules={{
            required: true,
          }}
          name="data.main_guest.type"
          renderWithFormControl={(fields) => (
            <Select {...fields}>
              {CUSTOMER_TRAVELERS_TYPES_LIST.map((item) => (
                <option key={item.id} value={item.id}>
                  {capitalize(
                    intl.formatMessage(customerTravelersTypeMessage, {
                      type: item.id,
                    }),
                  )}
                </option>
              ))}
            </Select>
          )}
        />

        <FormControlRHF
          control={control}
          label={intl.formatMessage({
            defaultMessage: "Civilité",
          })}
          rules={{
            required: true,
          }}
          name="data.main_guest.gender"
          renderWithFormControl={(fields) => (
            <Select {...fields}>
              <option value="">{"-"}</option>

              {CUSTOMER_TRAVELERS_GENDER_LIST.map((item) => (
                <option key={item.id} value={item.id}>
                  {capitalize(
                    intl.formatMessage(customerTravelersGenderMessage, {
                      gender: item.id,
                    }),
                  )}
                </option>
              ))}
            </Select>
          )}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="1rem">
        <FormControlRHF
          control={control}
          name="data.main_guest.lastname"
          label={intl.formatMessage({
            defaultMessage: "Nom",
          })}
          rules={{
            required: true,
          }}
        />

        <FormControlRHF
          control={control}
          name="data.main_guest.firstname"
          label={intl.formatMessage({
            defaultMessage: "Prénom",
          })}
          rules={{
            required: true,
          }}
        />

        <FormControlRHF
          control={control}
          type="datetime-picker"
          name="data.main_guest.birthdate"
          label={intl.formatMessage({
            defaultMessage: "Date de naissance",
          })}
          inputProps={{
            minWidth: "100%",
            enableKeyboardInput: true,
          }}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="1rem">
        <FormControlRHF
          control={control}
          name="data.main_guest.address"
          label={intl.formatMessage({
            defaultMessage: "Adresse",
          })}
          renderWithFormControl={(field) => (
            <InputAddress
              {...field}
              onAutoFill={handleAutoFill}
              placeholder={intl.formatMessage({
                defaultMessage: "Adresse 1 (Bâtiment, ...)",
              })}
            />
          )}
        />

        <FormControlRHF
          control={control}
          name="data.main_guest.address2"
          label={intl.formatMessage({
            defaultMessage: "Complément d'Adresse",
          })}
        />

        <FormControlRHF
          control={control}
          name="data.main_guest.zipcode"
          label={intl.formatMessage({
            defaultMessage: "Code postal",
          })}
        />

        <FormControlRHF
          control={control}
          name="data.main_guest.city"
          label={intl.formatMessage({
            defaultMessage: "Ville",
          })}
        />
      </SimpleGrid>

      <ObserverRHF
        names={["data.main_guest.type"]}
        render={({ values: [type] }) => (
          <>
            {type !== CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR && (
              <FormControlRHF
                control={control}
                rules={{
                  required: true,
                }}
                name="data.main_guest.company"
                label={intl.formatMessage({
                  defaultMessage: "Société",
                })}
              />
            )}

            {type === CUSTOMER_TRAVELERS_TYPE_VALUE_SOCIETY && (
              <>
                <FormControlRHF
                  control={control}
                  name="data.main_guest.siret"
                  label={intl.formatMessage({
                    defaultMessage: "SIRET",
                  })}
                  helperText={intl.formatMessage({
                    defaultMessage:
                      "Le numéro de SIRET de la société. Il se compose de 14 chiffres.",
                  })}
                />

                <FormControlRHF
                  control={control}
                  name="data.main_guest.vat_number"
                  label={intl.formatMessage({
                    defaultMessage: "Numéro VAT",
                  })}
                  helperText={intl.formatMessage({
                    defaultMessage:
                      "Le numéro de TVA intracommunautaire de la société. Il se compose de 2 lettres du code pays, suivi d'une clé en 2 chiffres, puis du SIREN .",
                  })}
                />
              </>
            )}
          </>
        )}
      />

      <FormControlRHF
        control={control}
        name="data.main_guest.country_id"
        label={intl.formatMessage({
          defaultMessage: "Pays",
        })}
        renderWithFormControl={(fields) => (
          <Select {...fields}>
            <option value="">-</option>

            {countries?.map?.((country) => (
              <option value={country.id} key={country.id}>
                {translate(country.name)}
              </option>
            ))}
          </Select>
        )}
      />

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="1rem">
        <FormControlRHF
          control={control}
          label={intl.formatMessage({
            defaultMessage: "Adresse email",
          })}
          rules={{
            required: workflow !== BOOKINGS_WORKFLOW_VALUE_DIRECT,
          }}
          name="data.main_guest.email"
          renderWithFormControl={(fields) => (
            <EmailWithBlacklistVerify {...fields} />
          )}
        />

        <FormObserver control={control} name="data.main_guest.phone1">
          {(phone) => (
            <FormObserver
              control={control}
              name="data.main_guest.phone1_country">
              {(phone1Country) => (
                <InputPhoneWithBlacklistVerify
                  label={intl.formatMessage({
                    defaultMessage: "Téléphone 1",
                  })}
                  helperText={intl.formatMessage({
                    defaultMessage:
                      "Le numéro de téléphone principal du client.",
                  })}
                  nameCountry="data.main_guest.phone1_country"
                  nameNumber="data.main_guest.phone1"
                  placeholder={intl.formatMessage({
                    defaultMessage: "Ex : 0652090909",
                  })}
                  value={phone}
                  valuePhoneCountry={phone1Country}
                  countries={countries ?? []}
                />
              )}
            </FormObserver>
          )}
        </FormObserver>

        <FormObserver control={control} name="data.main_guest.phone2">
          {(phone) => (
            <FormObserver
              control={control}
              name="data.main_guest.phone2_country">
              {(phone2Country) => (
                <InputPhoneWithBlacklistVerify
                  label={intl.formatMessage({
                    defaultMessage: "Téléphone 2",
                  })}
                  helperText={intl.formatMessage({
                    defaultMessage:
                      "Le numéro de téléphone secondaire du client.",
                  })}
                  nameCountry="data.main_guest.phone2_country"
                  nameNumber="data.main_guest.phone2"
                  placeholder={intl.formatMessage({
                    defaultMessage: "Ex : 0652090909",
                  })}
                  value={phone}
                  valuePhoneCountry={phone2Country}
                  countries={countries ?? []}
                />
              )}
            </FormObserver>
          )}
        </FormObserver>

        <FormObserver control={control} name="data.main_guest.phone3">
          {(phone) => (
            <FormObserver
              control={control}
              name="data.main_guest.phone3_country">
              {(phone3Country) => (
                <InputPhoneWithBlacklistVerify
                  label={intl.formatMessage({
                    defaultMessage: "Téléphone 3",
                  })}
                  nameCountry="data.main_guest.phone3_country"
                  nameNumber="data.main_guest.phone3"
                  placeholder={intl.formatMessage({
                    defaultMessage: "Ex : 0652090909",
                  })}
                  value={phone}
                  valuePhoneCountry={phone3Country}
                  countries={countries ?? []}
                />
              )}
            </FormObserver>
          )}
        </FormObserver>
      </SimpleGrid>

      <BookingsCustomerGuestList />
    </VStack>
  );
}

import { Box } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {boolean} [permanent]
 * @property {React.ReactNode} children
 */
/**
 * @param {Props & import("@chakra-ui/react").BoxProps} props
 */
export function Frame({ permanent, children, ...otherProps }) {
  return (
    <Box
      {...otherProps}
      borderWidth={permanent ? "1px" : { base: "0", xl: "1px" }}
      borderColor={otherProps.borderColor ?? "gray.200"}
      borderRadius="0.375rem"
      p={{ base: "0", xl: "1rem" }}>
      {children}
    </Box>
  );
}
